<template>
  <div class="py-3">
    <field
      v-for="field in request.fields"
      :key="field.name"
      :field="field"
      :conflict="conflicts[field.name]"
    />
    <div v-if="request.comment" class="mb-3">
      <small> Javaslattevő megjegyzése: </small><br />
      {{ request.comment }}
    </div>
    <div v-if="request.moderatorComment" class="mb-3">
      <small> Moderátor megjegyzése: </small><br />
      {{ request.moderatorComment }}
    </div>
    <b-row>
      <b-col class="mt-3 d-flex align-items-center">
        <small v-if="status !== 'pending'">
          <b-icon :icon="icon" :class="`text-${color}`" class="mr-1" />
          <user :uid="request.moderatorId" />
          <span class="d-none d-lg-inline"> {{ $t(status) }} ekkor: </span>
          {{ displayDate(request[`${status}At`]) }}
        </small>
      </b-col>
      <b-col
        cols="12"
        md="7"
        class="mt-3"
        v-if="
          currentUser &&
            (currentUser.role === 'admin' || currentUser.role === 'moderator')
        "
      >
        <div class="d-flex align-items-center">
          <b-button
            variant="danger"
            class="ml-auto"
            v-b-modal="_uid + '_' + request.id"
            :disabled="!!request.moderatorId"
            size="sm"
          >
            <b-icon-x-circle />
            <span class="d-none d-md-inline">
              Elutasít
            </span>
          </b-button>
          <b-modal
            title="Biztosan elutasítod?"
            :id="_uid + '_' + request.id"
            @ok="deny"
          >
            <b-form-group
              label="Mondd el, miért!"
              :label-for="_uid + '_' + request.id + '_comment'"
              description="Írj rövid indoklást a javaslattevőnek"
            >
              <b-form-textarea
                :id="_uid + '_' + request.id + '_comment'"
                v-model="moderatorComment"
                placeholder="Aa"
                rows="2"
                no-resize
                trim
              ></b-form-textarea>
            </b-form-group>
          </b-modal>
          <!--b-button
            variant="primary"
            class="ml-2"
            size="sm"
            :disabled="status !== 'pending'"
          >
            <b-icon-pencil-square />
            <span class="d-none d-md-inline">
              Módosít
            </span>
          </b-button-->
          <b-button
            variant="success"
            class="ml-2"
            @click="approve"
            :disabled="status == 'approved'"
            size="sm"
          >
            <b-icon-check2-circle />
            <span class="d-none d-md-inline">
              Jóváhagy
            </span>
          </b-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import Field from "./request_fields/Field.vue";
import User from "./User.vue";
import displayDate from "@/utils/displayDate";
import GameModuleMixin from "../mixins/GameModuleMixin";
import UserModuleMixin from "../mixins/UserModuleMixin";

export default {
  name: "Request",
  components: {
    User,
    Field
  },
  mixins: [GameModuleMixin, UserModuleMixin],
  props: {
    request: {
      required: true
    },
    game: {
      required: true
    }
  },
  data: () => ({ moderatorComment: null }),
  methods: {
    async approve() {
      if (
        Object.values(this.conflicts).length &&
        !(await this.$bvModal.msgBoxConfirm(
          "Eltérések vannak a jelenlegi állapothoz képest. Biztosan jóváhagyod?",
          {
            title: "Megerősítés",
            okTitle: "Igen",
            cancelTitle: "Mégse",
            centered: true
          }
        ))
      )
        return;
      try {
        await this.approveGameChangeRequest({
          gameId: this.game.id,
          crId: this.request.id
        });
        this.$bvToast.toast("Javaslat jóváhagyva!", {
          title: `Siker`,
          variant: "success",
          solid: true
        });
      } catch (error) {
        console.log(error);
        this.$bvToast.toast("Nem sikerült a jóváhagyás", {
          title: `Hiba`,
          variant: "danger",
          solid: true
        });
      }
    },
    async deny() {
      try {
        await this.denyGameChangeRequest({
          gameId: this.game.id,
          crId: this.request.id,
          moderatorComment: this.moderatorComment
        });
        this.$bvToast.toast("Javaslat elutasítva!", {
          title: `Siker`,
          variant: "success",
          solid: true
        });
      } catch (error) {
        console.log(error);
        this.$bvToast.toast("Nem sikerült az elutasítás", {
          title: `Hiba`,
          variant: "danger",
          solid: true
        });
      }
    },
    displayDate
  },
  computed: {
    conflicts() {
      const c = {};
      const eq = (a, b) => {
        if ((a === undefined || a === null) && (b === undefined || b === null))
          return true;
        return JSON.stringify(a) === JSON.stringify(b);
      };
      if (this.game.content && !this.request.moderatorId)
        for (const f of this.request.fields)
          if (
            !eq(this.game.content[f.name], f.valueFrom) &&
            !eq(this.game.content[f.name], f.valueTo)
          )
            c[f.name] = {
              expected: f.valueFrom,
              current: this.game.content[f.name]
            };
      return c;
    },
    status() {
      if (!!this.request.approvedAt && !this.request.deniedAt)
        return "approved";
      if (!this.request.approvedAt && !!this.request.deniedAt) return "denied";
      if (!!this.request.approvedAt && !!this.request.deniedAt)
        return [
          ["approved", new Date(this.request.approvedAt).getTime()],
          ["denied", new Date(this.request.deniedAt).getTime()]
        ].sort((a, b) => b[1] - a[1])[0][0];
      return "pending";
    },
    icon() {
      switch (this.status) {
        case "approved":
          return "check2-circle";
        case "denied":
          return "x-circle";
        default:
          return "question-circle";
      }
    },
    color() {
      switch (this.status) {
        case "approved":
          return "success";
        case "denied":
          return "danger";
        default:
          return "primary";
      }
    }
  }
};
</script>
